
.navbarButton {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 2.2rem;
    width: 12.5rem;
    z-index: 40;
}
.noticeButton {
    position: fixed;
    right: 12.5rem;
    bottom: 0;
    height: 2.2rem;
    width: 12.5rem;
    z-index: 40;
}
.loadingSpinner {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
}

.report-style-class {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 30;

}
